import React from "react"
import "../styles/global.css"
import { container } from "../styles/layout.module.css"
import Navigation from "./navigation"

const Layout = ({ pageTitle, children }) => {
  return (
    <div className={container}>
      <title>{pageTitle}</title>
      <Navigation />
      <main>
        {children}
      </main>
    </div>
  )
}

export default Layout
