import { Link } from "gatsby"
import React from "react"

import { links, linkItem } from "../styles/navigation.module.css"

export default function Navigation() {
  return (
    <nav>
      <div className={links}>
        <Link className={linkItem} to="/">
          Home
        </Link>
        <Link className={linkItem} to="/playlist">
          Music
        </Link>
      </div>
    </nav>
  )
}
