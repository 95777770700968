import React from "react"
import Layout from "../components/layout"
import { container, section, subSection } from "../styles/menu.module.css"

const MenuPage = () => {
  return (
    <Layout pageTitle={"Menu | Eames Maloney"}>
      <container className={container}>
        <container className={section}>
          <container className={subSection}>
            <h1>Starter</h1>
            <p>Vegan mezze.</p>
          </container>
        </container>

        <container className={section}>
          <container className={subSection}>
            <h1>Main</h1>
            <p>Summer style whole roast chicken with salsa verde.</p>
            <i>or, if vegetarian</i>
            <p>Wild mushroom, spinach and chesnut pithivier.</p>
          </container>
          <container className={subSection}>
            <h2>Sides</h2>
            <p>Crispy roasted new potatoes, seasonal herbs and garlic.</p>
            <p>Tenderstem broccili, garden peas and green beans.</p>
            <p>Red cabbage, beetroot and onion slaw.</p>
          </container>
        </container>

        <container className={section}>
          <container className={subSection}>
            <h1>Desert</h1>
            <p>Sticky toffee pud.</p>
          </container>
        </container>
      </container>
    </Layout>
  )
}

export default MenuPage
